import { useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { MapFieldSettings } from './MapFieldSettings';
import { Text } from '../Text';
import { FormContext } from '../../../context/form';
import Map from '../../../../../../components/map/map';
import { getCurrentLoggedInSessionTokenWithPromise, getCurrentLoggedInUserWithPromise } from '../../../../../../config/userPool';
import FieldLabel from '../../../FieldLabel';

const StyledMapField = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

export const MapField = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // Consume form values from the provider.
  // It makes selector components tightly coupled with the parent,
  // but this is by far the most efficient way of doing it.
  const { state } = useContext(FormContext)
  let [values, setValues] = state

  const {
    margin,
    textComponent,
    id,
    label: text,
    // mapLayerImage,
    disabled,
    required,
  } = props;

  const mapRef = useRef();
  const markers = useRef([]);
  const selected = useMemo(() => values.ohs && values.ohs[props.tabID - 1] && values.ohs[props.tabID - 1]?.[id] || {}, [values, id])

  const removePin = useCallback(async (markerId) => {
    markers.current = mapRef.current.removeMultiPin(markerId, markers.current)
    if (values.ohs[props.tabID - 1][id]) {
      // let deleteMap = values.ohs[props.tabID - 1]
      delete values.ohs[props.tabID - 1][id]
      setValues({
        ...values
      })
    }
    // setValues({
    //   ...values,
    //   [id]: selected.filter((location) => location.id !== markerId)
    // });
  }, [selected, values, setValues, id]);

  const addPin = useCallback((lat, lng) => {
    const markerId = uuidv4();
    let currentValues = { ...selected }
    currentValues = {
      name: "",
      Lat: lat,
      Lng: lng,
      id: markerId,
    };
    if (values.ohs) {
      if (values.ohs.length < props.tabID) {
        const ohs = [...values.ohs]
        const added = {
          [id]: { lat: JSON.stringify(lat), lng: JSON.stringify(lng) }
        }
        ohs.push(added)
        values = {
          ...values,
          ohs: ohs
        }
        setValues({
          ...values,
        });
      } else {
        if (values.ohs[props.tabID - 1]?.[id]) {
          let update = values.ohs[props.tabID - 1];
          update[id] = { lat: JSON.stringify(lat), lng: JSON.stringify(lng) };
          setValues({
            ...values,
          });
        } else {
          values.ohs[props.tabID - 1][id] = { lat: JSON.stringify(lat), lng: JSON.stringify(lng) };
          setValues({
            ...values,
          });
        }
      }
    } else {
      if (props.tabID > 0) {
        const ohs = []
        const added = {
          [id]: { lat: JSON.stringify(lat), lng: JSON.stringify(lng) }
        }
        ohs.push(added)
        values = {
          ...values,
          ohs: ohs
        }
        setValues({
          ...values,
        });
      }
    }
    const returnvalue = mapRef.current.addMutiPin({
      name: "",
      Lat: lat,
      Lng: lng,
      id: markerId,
    });
    markers.current.push(returnvalue);
    const popupContent = document.createElement("div");
    const atag = document.createElement("div");
    const title = document.createElement("div");
    atag.innerHTML = `<p data-marker-id=${markerId}>Delete </p>`;
    atag.style.cursor = "pointer";
    atag.addEventListener("click", (e) => {
      removePin(markerId);
    });
    title.innerHTML = returnvalue.name;
    popupContent.appendChild(title);
    popupContent.appendChild(atag);
    returnvalue.marker
      .setLngLat([lng, lat])
      .setPopup(returnvalue.mapboxgl.setDOMContent(popupContent))
      .addTo(returnvalue.map);
  }, [selected, removePin, id, setValues, values])

  const handleOnChange = useCallback((lat, lng) => {
    if (values.ohs) {
      if (props.tabID >= 1) {
        if (values.ohs.length < props.tabID) {
          setValues({
            ...values,
            ohs: [...values.ohs, { [id]: { lat: lat, lng: lng } }]
          })
        } else {
          if (values.ohs[props.tabID - 1]?.[id]) {
            let update = values.ohs[props.tabID - 1]
            update[id] = { lat: lat, lng: lng }
            setValues({
              ...values
            })
          } else {
            values.ohs[props.tabID - 1][id] = { lat: lat, lng: lng }
            setValues({
              ...values
            })
          }
        }
      }
    } else {
      if (props.tabID > 0) {
        setValues({
          ...values,
          ohs: [{
            [id]: { lat: lat, lng: lng }
          }]
        })
      } else {
        setValues({
          ...values,
          [id]: { lat: lat, lng: lng },
        })
      }
    }
  }, [selected, removePin, id, setValues, values])


  const AddMarkerOnPageLoad = () => {
    const markerId = uuidv4();
    // setEditDataLocation({
    //   ...editDataLocation,
    //   location_lat: lat,
    //   location_lng: lng,
    // });
    if (values.ohs && values.ohs[props.tabID - 1] && values.ohs[props.tabID - 1][id]) {
      const lat = JSON.parse(values.ohs[props.tabID - 1][id].lat)
      const lng = JSON.parse(values.ohs[props.tabID - 1][id].lng)
      const returnvalue = mapRef.current.addMutiPin({
        name: "",
        Lat: lat,
        Lng: lng,
        id: markerId,
      })
      // editDataLocation.map((i) => {
      //   console.log(i);

      //   const returnvalue = myRef.current.addMutiPin({
      //     name: i.name,
      //     Lat: i.Lat,
      //     Lng: i.Lng,
      //     id: i.id,
      //   });
      markers.current.push(returnvalue);
      const popupContent = document.createElement("div");
      const atag = document.createElement("div");
      const title = document.createElement("div");
      atag.innerHTML = `<p data-marker-id=${markerId}>Delete </p>`;
      atag.style.cursor = "pointer";
      atag.addEventListener("click", (e) => {
        removePin(markerId);
      });
      title.innerHTML = returnvalue.name;

      popupContent.appendChild(title);
      popupContent.appendChild(atag);
      returnvalue.marker
        .setLngLat([lng, lat])
        .setPopup(returnvalue.mapboxgl.setDOMContent(popupContent))
        .addTo(returnvalue.map);
      // Marker.push({
      //   id: markerId,
      //   name: returnvalue.name,
      //   marker: returnvalue.marker,
      //   Lng: lng,
      //   Lat: lat,
      // });
      // });
    }

    // console.log(returnvalue);
  };

  useEffect(() => {
    if (required) {
      // if(values.ohs && values.ohs[props.tabID - 1] && (values.ohs[props.tabID - 1][id] === undefined || values.ohs[props.tabID - 1][id] ===
      //   "")){
      //     props.checkValidation(true, `${id} ${props.tabID}`)
      //   }
      //  else if(values.ohs === undefined){
      //     props.checkValidation(true, `${id} ${props.tabID}`)

      //   }else{
      //     console.log("this one doing");
      //     props.checkValidation(false, `${id} ${props.tabID}`)
      //   }
      const cases =
        values.ohs === undefined
          ? true
          : values.ohs[props.tabID - 1] &&
            values.ohs[props.tabID - 1][id] !== undefined
            ? values.ohs[props.tabID - 1][id] === ""
              ? true
              : false
            : true;
      if (cases === true) {
        props.checkValidation(true, `${id} ${props.tabID}`)
      } else {
        props.checkValidation(false, `${id} ${props.tabID}`)
      }
    }
  })

  return (
    <StyledMapField
      ref={connect}
      className={cx([
        'w-full h-[400px] mb-4 detail-item-box field-validation__wrapper',
        {
          'pointer-events-none': disabled,
        }
      ])}
      margin={margin}
    >
      <FieldLabel {...textComponent} text={text} required={required} />
      <div
        className={cx([
          'w-full form__components__map',
        ])}
      >
        <Map
          // marker_for_sitrep={true}
          // const_image={true}
          showUtilisation={true}
          // wdc_case={true}
          // mapLayerImage={mapLayerImage}
          ref={mapRef}
          markerLat={parseFloat(selected?.[0]?.location_lat)}
          markerLng={parseFloat(selected?.[0]?.location_lng)}
          centerLat={parseFloat(selected?.[0]?.location_lat)}
          centerLng={parseFloat(selected?.[0]?.location_lng)}
          MultiPinSet={values.ohs && values.ohs[props.tabID - 1] && values.ohs[props.tabID - 1][id] ? false : true}
          // MultiPinSet = {isMulti}
          AddMarkerOnPageLoad={AddMarkerOnPageLoad}
          EditMultiPinData={selected}
          handleMultiPinChange={(e) => {
            addPin(e.lngLat?.lat, e.lngLat?.lng);
          }}
          EditMode={true}
        // handlePinChange={(lat, lng) => handleOnChange(JSON.stringify(lat), JSON.stringify(lng))}
        />
        {required && props.tabID >= 1 && (
          <>
            {values.ohs === undefined ? (<div className='OHS_FORM_validation'>
              This field is required!</div>) : values.ohs && (values.ohs[props.tabID - 1]?.[id] === undefined || values.ohs[props.tabID - 1]?.[id] === "") && (<div className='OHS_FORM_validation'>
                This field is required!</div>)}
          </>
        )}
      </div>
    </StyledMapField>
  );
};

MapField.craft = {
  displayName: 'Map Field',
  props: {
    margin: ['5', '0', '5', '0'],
    placeholder: 'Enter a value...',
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: MapFieldSettings,
  },
};
